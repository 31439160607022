<template>
  <div class="flex flex-1 surface-4 p-3 rounded max-w-screen-md	 ml-3">
    <div class="flex-1 p-3 surface-2 rounded ">
      <h1 class="heading-3-medium text-1">{{ $t('dataprotectionView.header') }}</h1>

      <div class="h-1 hr-divider mb-4"></div>
      <div class="bg-white rounded px-3 pt-10 pb-5">

        <div class="mb-6">
          <!--Intro-->
          {{ $t('dataprotectionView.intro') }}
        </div>

        <!--Personal information-->
        <h2 class="heading-5-medium text-1">{{ $t('dataprotectionView.personalinformation.title') }}</h2>
        <div class="h-1 hr-divider-sec mb-4"></div>
        <div class="mb-6">
          <p class="mb-4">{{ $t('dataprotectionView.personalinformation.paragraph1') }}</p>
          <p>{{ $t('dataprotectionView.personalinformation.paragraph2') }}</p>
        </div>

        <!--Cookies-->
        <h2 class="heading-5-medium text-1">{{ $t('dataprotectionView.cookies.title') }}</h2>
        <div class="h-1 hr-divider-sec mb-4"></div>
        <div class="mb-6">
          <p class="mb-4">{{ $t('dataprotectionView.cookies.paragraph1') }}<a
              href="https://www.fhnw.ch/de/impressum/optout_de">{{ $t('dataprotectionView.cookies.link') }}</a> {{ $t('dataprotectionView.cookies.paragraph2') }}</p>
        </div>

        <!--Web analysis and re-/marketing by Google-->
        <h2 class="heading-5-medium text-1">{{ $t('dataprotectionView.webanalysis.title') }}</h2>
        <div class="h-1 hr-divider-sec mb-5"></div>
        <div class="mb-6">

          <div class="mb-4">
            <!--Google Analytics-->
            <h2 class="heading-6-medium text-1 mb-3">{{ $t('dataprotectionView.webanalysis.googleAnalytics.title') }}</h2>
            <p class="mb-4">{{ $t('dataprotectionView.webanalysis.googleAnalytics.paragraph1') }}</p>
            <p class="mb-4">{{ $t('dataprotectionView.webanalysis.googleAnalytics.paragraph2') }}</p>
            <p class="mb-4">{{ $t('dataprotectionView.webanalysis.googleAnalytics.paragraph3') }}</p>
          </div>

          <div class="mb-4">
            <!--Google Ads and Google conversion tracking-->
            <h2 class="heading-6-medium text-1 mb-3">{{ $t('dataprotectionView.webanalysis.googleAds.title') }}</h2>
            <p class="mb-4">{{ $t('dataprotectionView.webanalysis.googleAds.paragraph') }}</p>
          </div>
          <div class="mb-4">

            <!--Google Tag Manager-->
            <h2 class="heading-6-medium text-1 mb-3">{{ $t('dataprotectionView.webanalysis.googleTagManager.title') }}</h2>
            <p class="mb-4">{{ $t('dataprotectionView.webanalysis.googleTagManager.paragraph') }}</p>
          </div>
          
          <div class="mb-4">
            <!--Google privacy policy and information-->
            <h2 class="heading-6-medium text-1 mb-3">{{ $t('dataprotectionView.webanalysis.googlePrivacyPolicy.title') }}</h2>
            <p class="mb-4">{{ $t('dataprotectionView.webanalysis.googlePrivacyPolicy.paragraph') }}</p>
            <ul class="ml-5">
              <li><a class="link" href="https://policies.google.com/privacy?hl=en" target="_blank">{{ $t('dataprotectionView.webanalysis.googlePrivacyPolicy.list1') }}</a></li>
              <li><a class="link" href="https://policies.google.com/technologies/ads?hl=en" target="_blank">{{ $t('dataprotectionView.webanalysis.googlePrivacyPolicy.list2') }}</a></li>
              <li><a class="link" href="https://www.google.com/tagmanager/use-policy.html" rel="noopener"
                  target="_blank">{{ $t('dataprotectionView.webanalysis.googlePrivacyPolicy.list3') }}</a></li>
            </ul>
          </div>
          
          <div class="mb-9">
            <!--Third party services-->
            <h2 class="heading-6-medium text-1 mb-3">{{ $t('dataprotectionView.thirdPartyServices.title') }}</h2>
            <p class="mb-4">{{ $t('dataprotectionView.thirdPartyServices.paragraph1') }}</p>
            <p class="mb-4">{{ $t('dataprotectionView.thirdPartyServices.paragraph2') }}</p>
           
            <!--Section 3.5 Accordion Items-->
            <div class="accordion">
            <div v-for="(item, index) in accordionItems" :key="index" class="accordion-item" :class="{ 'open': item.open }" @click="toggleAccordion(index)">
              <div class="accordion-header">
                <span class="label">{{ $t(`dataprotectionView.accordionItems.${item.key}.title`) }}</span>
                <span class="accordion-icon">{{ item.open ? 'x' : '+' }}</span>
              </div>
              <transition name="accordion">
                <div class="accordion-content" v-show="item.open">
                  <p class="mb-4">{{ $t(`dataprotectionView.accordionItems.${item.key}.paragraph1`) }}</p>
                  <p v-if="item.paragraph2" class="mb-4">{{ $t(`dataprotectionView.accordionItems.${item.key}.paragraph2`) }}</p>
                  <ul v-if="item.links">
                    <li v-for="(link, linkIndex) in item.links" :key="linkIndex">
                      <a :href="link.url" class="link" target="_blank">{{ $t(link.textKey) }}</a>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>

          </div>
          <!--Disclaimer-->
          <div class="mb-4">
            <h2 class="heading-6-medium text-1 mb-3">{{ $t('dataprotectionView.disclaimer.title') }}</h2>
            <p class="mb-4">{{ $t('dataprotectionView.disclaimer.paragraph') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'


const store = useStore()
const router = useRouter()
const route = useRoute()

const lang = route?.query?.lang || 'en';

const accordionItems = reactive([
  {
    key: 'cleverReach',
    open: false,
    links: [
      { url: 'https://www.cleverreach.com/en/privacy-policy/', textKey: 'dataprotectionView.accordionItems.cleverReach.link' }
    ]
  },
  {
    key: 'facebook',
    open: false,
    paragraph2: true,
    links: [
      { url: 'https://www.facebook.com/policy.php', textKey: 'dataprotectionView.accordionItems.facebook.link1' },
      { url: 'https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen', textKey: 'dataprotectionView.accordionItems.facebook.link2' }
    ]
  },
  {
    key: 'googleFonts',
    open: false,
    links: [
      { url: 'https://www.google.com/policies/privacy', textKey: 'dataprotectionView.accordionItems.googleFonts.link' }
    ]
  },
  {
    key: 'instagram',
    open: false,
    paragraph2: true,
    links: [
      { url: 'http://instagram.com/about/legal/privacy/', textKey: 'dataprotectionView.accordionItems.instagram.link1' }
    ]
  },
  {
    key: 'issuu',
    open: false,
    links: [
      { url: 'https://issuu.com/legal/privacy', textKey: 'dataprotectionView.accordionItems.issuu.link' }
    ]
  },
  {
    key: 'linkedin',
    open: false,
    links: [
      { url: 'https://www.linkedin.com/legal/privacy-policy', textKey: 'dataprotectionView.accordionItems.linkedin.link1' },
      { url: 'https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out', textKey: 'dataprotectionView.accordionItems.linkedin.link2' }
    ]
  },
  {
    key: 'microsoft',
    open: false,
    links: [
      { url: 'https://privacy.microsoft.com/de-de/privacystatement', textKey: 'dataprotectionView.accordionItems.microsoft.link' }
    ]
  },
  {
    key: 'openStreetmap',
    open: false,
    links: [
      { url: 'https://wiki.osmfoundation.org/wiki/Privacy_Policy', textKey: 'dataprotectionView.accordionItems.openStreetmap.link' }
    ]
  },
  {
    key: 'reddit',
    open: false,
    links: [
      { url: 'https://www.reddit.com/en-us/policies/privacy-policy', textKey: 'dataprotectionView.accordionItems.reddit.link' }
    ]
  },
  {
    key: 'slideShare',
    open: false,
    links: [
      { url: 'https://support.scribd.com/hc/de/articles/210129366-Privacy-policy', textKey: 'dataprotectionView.accordionItems.slideShare.link' }
    ]
  },
  {
    key: 'snapchat',
    open: false,
    links: [
      { url: 'https://snap.com/en-GB/privacy/privacy-policy', textKey: 'dataprotectionView.accordionItems.snapchat.link' }
    ]
  },
  {
    key: 'soundCloud',
    open: false,
    links: [
      { url: 'https://soundcloud.com/pages/privacy', textKey: 'dataprotectionView.accordionItems.soundCloud.link' }
    ]
  },
  {
    key: 'switchtube',
    open: false,
    links: [
      { url: 'https://help.switch.ch/tube/conditions/', textKey: 'dataprotectionView.accordionItems.switchtube.link' }
    ]
  },
  {
    key: 'tiktok',
    open: false,
    links: [
      { url: 'https://www.tiktok.com/legal/privacy-policy-eea', textKey: 'dataprotectionView.accordionItems.tikTok.link1' },
      { url: 'https://support.tiktok.com/de/account-and-privacy/account-privacy-settings', textKey: 'dataprotectionView.accordionItems.tikTok.link2' }
    ]
  },
  {
    key: 'x',
    open: false,
    links: [
      { url: 'http://twitter.com/privacy', textKey: 'dataprotectionView.accordionItems.x.link1' },
      { url: 'https://twitter.com/settings/account', textKey: 'dataprotectionView.accordionItems.x.link2' }
    ]
  },
  {
    key: 'vimeo',
    open: false,
    links: [
      { url: 'https://vimeo.com/privacy', textKey: 'dataprotectionView.accordionItems.vimeo.link' }
    ]
  },
  {
    key: 'xing',
    open: false,
    links: [
      { url: 'https://privacy.xing.com/en', textKey: 'dataprotectionView.accordionItems.xing.link' }
    ]
  },
  {
    key: 'yoastSeo',
    open: false,
    links: [
      { url: 'https://yoast.com/privacy-policy/', textKey: 'dataprotectionView.accordionItems.yoastSeo.link' }
    ]
  },
  {
    key: 'youTube',
    open: false,
    links: [
      { url: 'https://www.google.com/policies/privacy', textKey: 'dataprotectionView.accordionItems.youTube.link1' },
      { url: 'https://myaccount.google.com/intro', textKey: 'dataprotectionView.accordionItems.youTube.link2' }
    ]
  }
]);

function toggleAccordion(index) {
  accordionItems[index].open = !accordionItems[index].open;
}

</script>
<style scoped>
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-800);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: var(--color-primary-600);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-700) !important;
  border-radius: 20px;
  border: 0px;
}



ul {
  list-style-type: disc;
}

.accordion-item {
  margin-bottom: 4px;
  cursor: pointer;
}

.accordion-header:hover {
  background-color: #deded9;
}

.accordion-icon {
  font-size: 1.5rem;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f1f1ee;
  align-items: center;
}

.accordion-item.open .accordion-header {
  background-color: #fff48d;
}

.accordion-content {
  padding: 20px;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  max-height: 0;

}

.accordion-item.open .accordion-content {
  padding: 20px;
  margin-bottom: 20px;
  max-height: 300px;
  /* Adjust based on content */

}

.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.accordion-enter,
.accordion-leave-to

/* .accordion-leave-active in <2.1.8 */
  {
  padding: 0 10px;
}

@keyframes slide-up {
  from {
    max-height: 0;
    padding: 0 20px;
  }

  to {
    max-height: 300px;
    /* Adjust based on content */
    padding: 20px;
  }
}

@keyframes slide-down {
  from {
    padding: 20px;
    max-height: 300px;
    /* Adjust based on content */

  }

  to {
    max-height: 0;
    padding: 0 20px;
  }
}

.accordion-enter-active {
  animation: slide-up 0.3s ease forwards;
}

.accordion-leave-active {
  animation: slide-down 0.3s ease forwards;
}

a {
  text-decoration: underline;
  font-weight: bold;
}
</style>