<template>
  <div class="flex flex-1 surface-4 p-3">
    <div class="flex-1 p-3 surface-2 rounded' max-w-screen-md ml-3">
      <h1 class="heading-3-medium text-1">{{ $t('imprintView.header') }}</h1>

      <div class="h-1 hr-divider mb-4"></div>
      <div class="bg-white rounded px-3 pt-10 pb-5">
        <div class="mb-6">
          {{ $t('imprintView.responsibility') }}
        </div>

        <h2 class="heading-5-medium text-1">{{ $t('imprintView.contact') }}</h2>
        <div class="h-1 hr-divider-sec mb-4"></div>
        <div class="mb-6">
          <p class="mb-4">
            {{ $t('imprintView.contactDetails.line1') }}<br>
            {{ $t('imprintView.contactDetails.line2') }}<br>
            {{ $t('imprintView.contactDetails.line3') }}<br>
            {{ $t('imprintView.contactDetails.line4') }}<br>&nbsp;<br>
            {{ $t('imprintView.contactDetails.line5') }}<br>
            {{ $t('imprintView.contactDetails.line6') }}<br>
            <a href="http://www.btso.ch" target="_blank" rel="noreferrer noopener">{{ $t('imprintView.contactDetails.line7') }}</a><br>&nbsp;<br>
            {{ $t('imprintView.contactDetails.line8') }}<br>
            {{ $t('imprintView.contactDetails.line9') }}<br>
            {{ $t('imprintView.contactDetails.line10') }}<br>&nbsp;<br>
            {{ $t('imprintView.contactDetails.line11') }}
          </p>
        </div>

        <h2 class="heading-5-medium text-1">{{ $t('imprintView.disclaimer') }}</h2>
        <div class="h-1 hr-divider-sec mb-4"></div>
        <div class="mb-6">
          <p class="mb-4">{{ $t('imprintView.disclaimerText') }}</p>
          <p class="font-bold">{{ $t('imprintView.pictureCreditsTitle') }}</p>
          <p class="mb-4">{{ $t('imprintView.pictureCredits') }}</p>
        </div>

        <h2 class="heading-5-medium text-1">{{ $t('imprintView.terms') }}</h2>
        <div class="h-1 hr-divider-sec mb-4"></div>
        <div class="mb-6">
          <a class="link" href="https://www.fhnw.ch/plattformen/forschungsprojekte-btso/wp-content/uploads/sites/390/AGB-BTSO-2024-03.pdf">{{ $t('imprintView.termsLink') }}</a>
        </div>

        <h2 class="heading-5-medium text-1">{{ $t('imprintView.accessibility') }}</h2>
        <div class="h-1 hr-divider-sec mb-4"></div>
        <div class="mb-6">
          <p class="mb-4">{{ $t('imprintView.accessibilityText') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'


const store = useStore()
const router = useRouter()
const route = useRoute()

const lang = route?.query?.lang || 'de';



</script>
<style scoped>
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-800);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: var(--color-primary-600);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-700) !important;
  border-radius: 20px;
  border: 0px;
}
</style>