<template>
    <!--{{ currentStepNumber }} -->
    <div class="wizard p-3 flex items-center">
        <div :class="{'opacity-60': isDisabled }" class="flex items-center justify-between gap-8 w-full">

            <button @click.prevent="switched(currentStepNumber - 1)" class="button-prev w-14 h-14 --mr-8 rounded-xl flex items-center justify-center"><IconArrowLeft class="w-6"/></button>

            <div class="wizard-progress relative flex flex-1 flex-shrink-0 items-center justify-between">
                <div class="wizard-progress-bar absolute z-10" :style="{ width: getProgressWidth() }"></div>
                <button :disabled="isDisabled" v-for="(name, step, index) in stepMap" @click.prevent="switched(step)" :class="{ 'active': step <= currentStepNumber }" class="button-step rounded-full w-6 h-6 flex items-center justify-center -ml-3 --opacity-40"><span class="leading-none relative" style="left: -1.3px;">{{ step }}</span></button>
            </div>

            <button :disabled="isDisabled" @click.prevent="switched(Number(currentStepNumber) + 1)" class="button-next heading-4-bold-s px-4 h-14 --ml-4 rounded-xl flex items-center justify-center uppercase">{{ $t('buttonNext') }}<IconChevronRight class="ml-3"/></button>
        </div>
    </div>
</template>
<script setup>

import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import IconArrowLeft from '@/views/components/icons/IconArrowLeft.vue'
import IconChevronRight from '@/views/components/icons/IconChevronRight.vue'

const router = useRouter()
const route = useRoute()

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

const props = defineProps({
    'step': Number,
    'disabled': Boolean
})

const emit = defineEmits(['switched'])

/*let stepMap = {
    'test-sequence-anamnese': 1,
    'test-sequence-strabismus': 2,
    'test-sequence-test-order': 3,
    'test-sequence-control-1': 4,
    'test-sequence-control-2': 5,
    'test-sequence-control-3': 6,
    'test-sequence-control-4': 7,
    'test-sequence-control-5': 8,
    'test-sequence-control-6': 9
}*/

let stepMap = {
    1: 'test-sequence-anamnese',
    2: 'test-sequence-strabismus',
    3: 'test-sequence-test-order',
    4: 'test-sequence-control',
    5: 'test-sequence-control',
    6: 'test-sequence-control',
    7: 'test-sequence-control',
    8: 'test-sequence-control',
    9: 'test-sequence-control'
}

let getCurrentStepNumber = computed(() => props.step)

let isDisabled = computed(() => props.disabled)

let maxSteps = 9

let currentStepNumber = ref()

let getProgressWidth = function () {
    // let w = (getCurrentStepNumber.value - 1) / maxSteps * 100 + '%'
    // return 'calc(' + w + ' + ' + ((getCurrentStepNumber.value - 1) * 12) + 'px)'
    return ((getCurrentStepNumber.value - 1) / (maxSteps - 1) * 100) + '%'
}

const lang = route?.query?.lang || 'de';

let goToTestEvaluationPage = function () {
    router.push({
        name: 'test-evaluation',
        params: {
            ...route.params,
            patientId,
            treatmentId,
        },
        query: {
          lang
        }
    })
}

let switched = function (step) {

    if(outOfBounds(step))  {
        if(step > 0) {
            goToTestEvaluationPage()
        }

        return
    }

    //let routeName = Object.keys(stepMap).find(k => stepMap[k] === step);

    let routeName = stepMap[step]

    currentStepNumber.value = step

    emit('switched', { step, routeName })
}

let outOfBounds= function (step) {
    return step <= 0 || step > maxSteps
}


onMounted(() => {
    console.log(props)
    console.log(isDisabled.value)
    console.log(getCurrentStepNumber.value)
    currentStepNumber.value = getCurrentStepNumber.value
})
</script>
<style scoped>
    .wizard {
        border: 1px solid var(--color-primary-50);
        background: var(--color-shade-0);
        border: 1px solid var(--color-primary-50);
        box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.05);
        /*border-radius: 16px;*/
        border-radius: var(--radius-lg);
    }
    .wizard-progress:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 10px;
        /*background: var(--color-primary-50);*/
        background: var(--color-shade-1);
        z-index: 1;
    }

    .wizard-progress-bar {
        z-index: 2;
        height: 10px;
        /*background: var(--color-primary-700);*/
        background: var(--color-primary-1);
    }

    .button-prev {
        /*background: #ebf9ff;
        color: var(--color-primary-700);*/
        background-color: var(--color-shade-3);
        color:var(--color-shade-1);
        transition: all .15s ease-in;
        border-radius: var(--radius-lg);
        flex-shrink: 0;
    }

    .button-prev:hover {
        /*background: var(--color-primary-100);
        color: var(--color-primary-700);*/
        background-color: var(--color-shade-3);
        color:var(--color-shade-1);
        opacity: 0.7;
    }

    .button-next {
        /*background: var(--color-secondary-400);*/
        background: var(--color-primary-1);
        color: var(--color-shade-1);
        line-height: 1;
        transition: all .15s ease-in;
        border-radius: var(--radius-lg);
    }

    .button-next:hover {
        /*background: var(--color-secondary-600);*/
        opacity: 0.7;
    }

    .button-step {
        /*background: var(--color-primary-50);*/
        background: var(--color-shade-1);
        color: var(--color-shade-0);
        /*font-style: italic;*/
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        z-index: 10;
        transition: all .15s ease-in;
        margin: 0;
    }

    .button-step:first-of-type {
        margin-left: -10px;
    }

    .button-step:last-of-type {
        margin-right: -10px;
    }

    .button-step:hover {
        /*background: var(--color-primary-100) !important;*/
        background: var(--color-primary-1) !important;
        color: var(--color-shade-1) !important;

    }
    .button-step.active:hover {
        /*background: var(--color-primary-600) !important;*/
        background: var(--color-primary-1) !important;
    }

    .button-step.active {
        background: var(--color-primary-700);
        color: var(--color-shade-1) !important;
        background: var(--color-primary-1) !important;

    }

</style>