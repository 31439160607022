<template>
  <main :class="{ 'padding-l-200': showMenu }" class="flex min-h-screen">
    <section class="menu-section" v-if="showMenu">
      <AppMenu />
    </section>

    <RouterView :key="$route.path" />

  </main>
  <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="cookieConsentModal">
    <template v-slot:header>
      <template></template>
    </template>
    <template v-slot:body scope="props">
      <div class="flex flex-col text-center justify-between">
        <p class="w-100 mx-auto mt-7 leading-4 overflow-y-auto px-12 whitespace-pre-line">
          {{$t('app.cookieConsentText') }} <RouterLink class="text-primary underline" :to="'/data-protection'"
            :query="{ lang }" @click.native="closeModal">{{
              $t('app.cookieConsentLinkText') }}</RouterLink>
        </p>
        <div class="flex items-center justify-between mt-7 mb-3">
          <button @click="acceptCookies()" class="button-primary w-96 mx-auto">
            {{ $t('app.cookieConsentButton') }}
          </button>

        </div>
      </div>
    </template>
    <template v-slot:footer>
      <template></template>
    </template>
  </AppConfirmModal>

</template>

<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'

import AppMenu from '@/views/components/common/AppMenu.vue'


const store = useStore()
const router = useRouter()
const route = useRoute()
const cookieConsentModal = ref()

let showCookieModal = async function () {
  await cookieConsentModal.value.show()
}
let acceptCookies = async function () {
  $cookies.set('btso_cookie_consent', 1, '50y')
  cookieConsentModal.value.close()
}
const closeModal = () => {
  cookieConsentModal.value.close()
}


const showMenu = computed(() => store.getters['app/showMenu'])

watch(route, (newRoute) => {
  console.log('ruta:', newRoute.name)
  const excludedRoutes = ['login', 'register', 'test-login']
  const cookieConsent = $cookies.get('btso_cookie_consent')
  console.log('cookieConsent:', cookieConsent)
  
  if (!excludedRoutes.includes(newRoute.name) && (!cookieConsent || cookieConsent == 0)) {
    console.log('Showing cookie modal')
    showCookieModal()
  } else {
    console.log('Not showing cookie modal')
  }
}, { immediate: true })



// remove when we will have app view
//router.push({ name: 'login' })

</script>

<style>
@import '@/assets/app.css';
</style>
