<template>
  <div class="flex justify-center items-center">
    <IconEarth class="w-5 h-5 fill-neutral-500"/>
    <button
        @click="onClick('de')"
        :class="{ 'px-2 cursor-pointer text-neutral-500 w-9 uppercase text-md hover:font-bold': true,
         'font-bold': currentLanguage === 'de' }">De</button>
    <div class="text-neutral-500">|</div>
    <button
        @click="onClick('en')"
        :class="{ 'px-2 cursor-pointer text-neutral-500 w-9 uppercase text-md hover:font-bold': true,
         'font-bold': currentLanguage === 'en' }">En</button>
    <div class="text-neutral-500">|</div>
    <button
        @click="onClick('it')"
        :class="{ 'px-2 cursor-pointer text-neutral-500 w-9 uppercase text-md hover:font-bold': true,
         'font-bold': currentLanguage === 'it' }">It</button>
    <div class="text-neutral-500">|</div>
    <button
        @click="onClick('fr')"
        :class="{ 'px-2 cursor-pointer text-neutral-500 w-9 uppercase text-md hover:font-bold': true,
         'font-bold': currentLanguage === 'fr' }">Fr</button>
    
  </div>
</template>

<script setup>
import IconEarth from '@/views/components/icons/IconEarth.vue'

const currentLanguage = window.localStorage.getItem('lang');
const onClick = async (lang) => {
  if (currentLanguage !== lang) {
    window.localStorage.setItem('lang', lang);
    const url = new URL(window.location.href);
    url.searchParams.set('lang', lang);
    window.history.pushState(null, '', url.toString());
    window.location.reload(true);
  }
}
</script>