<template>

  <div class="flex flex-col justify-between h-screen">
    <div class="main-menu py-3 px-3">

      <!-- <img alt="BTSO logo" class="logo" src="@/assets/logo.svg" width="125" height="125" /> -->
      <div class="flex items-center w-full brand">
        <BtsoLogo class="w-32" />
      </div>
      <div>
        <nav class="mt-3">
          <ul class="text-p-3-bold">
            <li class="py-2">
              <RouterLink :to="{ name: 'patients', query: { lang } }" :class="{ active: currentRoute == 'patients' }"
                class="flex items-center w-full menu-item-link">
                <IconUsers class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">
                  {{ $t('appMenu.menu.firstTab') }}</span>
              </RouterLink>
            </li>
            <li class="py-2">
              <RouterLink v-if="patientId && treatmentId"
                :to="{ name: 'test-sequence-anamnese', params: { patientId: patientId, treatmentId: treatmentId }, query: { lang } }"
                :class="{ active: isTestSequenceRouteActive(currentRoute) }" class="flex items-center menu-item-link">
                <IconBoxUser class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}"> {{ $t('appMenu.menu.secondTab') }}</span>
              </RouterLink>
              <a v-else href="javascript:" class="flex items-center menu-item-link disabled">
                <IconBoxUser class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.secondTab') }}</span>
              </a>
            </li>
            <li class="py-2">
              <RouterLink v-if="patientId && treatmentId"
                :to="{ name: 'test-sequence-fast', params: { patientId: patientId, treatmentId: treatmentId }, query: { lang } }"
                :class="{ active: isFastTestSequenceRouteActive(currentRoute) }"
                class="flex items-center menu-item-link">
                <IconDetail class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.thirdTab') }}</span>
              </RouterLink>
              <a v-else href="javascript:" class="flex items-center menu-item-link disabled">
                <IconDetail class="w-4 mr-2" />
                <span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.thirdTab') }}</span>
              </a>
            </li>
            <li class="py-2">
              <RouterLink :to="{ name: 'documents', query: { lang } }" class="flex items-center menu-item-link">
                <IconCloud class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.fourthTab') }}</span>
              </RouterLink>
            </li>
            <li class="py-2">
              <RouterLink :to="{ name: 'users', query: { lang } }" :class="{ active: currentRoute == 'users' }"
                class="flex items-center menu-item-link">
                <IconPatientSearch class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.fifthTab')
                  }}</span>
              </RouterLink>
            </li>

            <li class="py-2">
              <RouterLink :to="{ name: 'settings', query: { lang } }" :class="{ active: currentRoute == 'settings' }"
                class="flex items-center menu-item-link">
                <IconSettings class="w-4 mr-2" /><span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.sixthTab') }}</span>
              </RouterLink>
            </li>



            <template v-if="!authenticated">
              <li class="py-2">
                <RouterLink :to="{ name: 'login', query: { lang } }" class="menu-item-link">Login</RouterLink>
              </li>
              <li class="py-2">
                <RouterLink :to="{ name: 'register', query: { lang } }" class="menu-item-link">Registration</RouterLink>
              </li>
            </template>
          </ul>
        </nav>

      </div>
    </div>

    <div v-if="authenticated" class="main-menu py-3 px-3 text-p-3-bold">

      <div v-if="currentRoute == 'test-evaluation' && documents" class="documentation mb-4 p-2 rounded-lg opacity-40">
        <div class="mx-auto flex items-center justify-center">
          <IconPdfBig />
        </div>

        <h3 class="py-4">Documentation</h3>
        <p>
          Please check our extensive documentation if you need any
          additional information on the subject.
        </p>

        <div v-if="false" class="documentation-inner mt-4 p-2 rounded scroll-auto text-center flex-1">
          <ul class="text-white max-h-96 overflow-y-auto pr-1">
            <li v-for="document in documents"><a :href="document.url" target="_blank"
                class="mb-2 flex items-center justify-center brand doc-link-btn bg-white rounded w-full h-7"><span
                  class="overflow-hidden truncate px-2">{{ document.name }}</span></a></li>
          </ul>
        </div>

      </div>
      <nav>
        <ul>
          <li>
            <LanguageSection />
          </li>
        </ul>
      </nav>

      <nav>
        <ul>
          <li class="py-2">
            <RouterLink :to="{ name: 'data-protection', query: { lang } }"
              :class="{ active: currentRoute == 'data-protection' }" class="flex items-center menu-item-link">
              <span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.seventhTab') }}</span>
            </RouterLink>
          </li>
          <li class="py-2">
            <RouterLink :to="{ name: 'imprint', query: { lang } }" :class="{ active: currentRoute == 'imprint' }"
              class="flex items-center menu-item-link">
              <span class="menu-tab-text" :class="{'text-nowrap whitespace-nowrap': lang == 'de' || lang == 'en'}">{{ $t('appMenu.menu.eighthTab') }}</span>
            </RouterLink>
          </li>
          <li class="py-2"><a href="#" @click.prevent="logout()"
              class="menu-item-link flex items-center justify-between"><span>{{ $t('appMenu.menu.logout') }}</span>
              <IconArrowRight />
            </a></li>
        </ul>
      </nav>
      <!--<li class="py-2"><RouterLink to="/" class="menu-item-link">Home</RouterLink></li>
      <li class="py-2"><RouterLink to="/about" class="menu-item-link">About</RouterLink></li>-->

    </div>

  </div>
 

</template>

<script setup>
import { useRouter, useRoute, RouterLink, RouterView } from 'vue-router'
import { useStore } from 'vuex'
import { computed, watch, onMounted, ref } from 'vue'

import BtsoLogo from '@/views/components/icons/BtsoLogo.vue'
import IconPatientSearch from '@/views/components/icons/IconPatientSearch.vue'
import IconUsers from '@/views/components/icons/IconUsers.vue'
import IconBoxUser from '@/views/components/icons/IconBoxUser.vue'
import IconDetail from '@/views/components/icons/IconDetail.vue'
import IconCloud from '@/views/components/icons/IconCloud.vue'
import IconSettings from '@/views/components/icons/IconSettings.vue'

import IconArrowRight from '@/views/components/icons/IconArrowRight.vue'
import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'
import LanguageSection from "@/views/components/LanguageSection.vue";

const lang = window.localStorage.getItem('lang') || 'de';

const router = useRouter()
const route = useRoute()

// let currentPathObject = router.currentRoute.value;

// console.log('HERE')

// console.log(currentPathObject)

const store = useStore()



let currentRoute = computed(() => route.name)

let patientId = computed(() => route.params.patientId || sessionStorage.getItem('patientId'))
let treatmentId = computed(() => route.params.treatmentId || sessionStorage.getItem('treatmentId'))

let documents = computed(() => store.getters['documents/all'])

//const user = computed(() => store.getters.user)
//const authenticated = computed(() => store.getters.authenticated)

//namespaced vuex store module
const user = computed(() => store.getters['auth/user'])
const authenticated = computed(() => store.getters['auth/authenticated'])

const logout = async () => {
  await store.dispatch('auth/logout').catch((err) => {
  })

  router.push({
    name: 'login',
    query: {
      lang: window.localStorage.getItem('lang') || 'de'
    }
  })
}

const saveSessionData = () => {
  sessionStorage.setItem('patientId', patientId.value)
  sessionStorage.setItem('treatmentId', treatmentId.value)
}

watch(() => route.name, async () => {
  if (route.name == 'documents') {
    return
  }
  await store.dispatch('documents/all', { treatmentId: treatmentId.value, lang: window.localStorage.getItem('lang') || 'de' })
})


let isTestSequenceRouteActive = function (currentRoute) {
  saveSessionData()
  return [
    'test-sequence-anamnese',
    'test-sequence-strabismus',
    'test-sequence-test-order',
    'test-sequence-control',
  ].indexOf(currentRoute) > -1
}

let isFastTestSequenceRouteActive = function (currentRoute) {
  saveSessionData()
  return [
    'test-sequence-fast',
  ].indexOf(currentRoute) > -1
}



onMounted(async () => {
  console.log('on mounted')
  console.log(store)

  if (currentRoute.value == 'test-evaluation') {
    console.log('test-evaluation')
    await store.dispatch('documents/all', { treatmentId: treatmentId.value, lang })
  }
  


})

</script>

<style scoped>
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-800);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: var(--color-primary-600);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-shade-1) !important;
  border-radius: 20px;
  border: 0px;
}

h1 {
  font-weight: 500;
  font-size: 2.6rem;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.menu-item-link {
  color: var(--color-shade-1);
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {

  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}

.documentation {
  background: var(--color-primary-500);
}

.documentation-inner {
  background: var(--color-primary-600);
}

.documentation h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
}

.documentation p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
}

a.disabled {
  pointer-events: none;
  color: var(--color-shade-dark-mute);
}




</style>
