<template>
    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 p-3 surface-2 rounded'">
            <h1 class="heading-3-medium text-1">{{ $t('settingsView.settings') }}</h1>

            <div class="h-1 hr-divider mb-4"></div>
            <div class="bg-white rounded px-0 pt-1 pb-5 min-h-screen">

                <div class="tab-container flex items-center justify-start mb-4">
                    <div class="tabs grid grid-cols-3 gap-0.5 text-sm h-14">
                      <a href="javascript:" @click="setActiveTab('t1')" :class="{ 'active': activeTab == 't1' }" class=" flex items-center justify-center px-6" tabindex="0">{{ $t('settingsView.security') }}</a>
                      <a href="javascript:" @click="setActiveTab('t2')" :class="{ 'active': activeTab == 't2' }" class=" flex items-center justify-center px-6" tabindex="0">{{ $t('settingsView.opinion') }}</a>
                    </div>
                </div>

                <div v-show="activeTab == 't1'" class="px-6 pt-8 flex flex-col h-full max-h-full overflow-y-auto">

                    <div class="w-1/3">

                        <div class="section mb-8">
                            <h3 class="heading-6-medium border-b">{{ $t('settingsView.tFAuthentication') }}</h3>
                            <div class="pt-2">
                                <div class="flex items-center py-2">
                                    <input name="auth" id="auth-email" type="radio" class="relative -top-0.5" disabled checked>
                                    <label for="auth-email" class="ml-2 opacity-80">{{ $t('settingsView.email') }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="section mb-8">
                            <h3 class="heading-6-medium border-b">{{ $t('settingsView.password') }}</h3>
                            <div class="pt-2">
                                <form v-on:submit.prevent="changePassword">
                                    <div :class="{ 'has-error': errors.password_form.current_password[0] }">
                                        <input v-model="form.password_form.current_password" @change="reset('password_form', 'current_password')" type="password" :placeholder="$t('settingsView.currentPassword')" class="mt-3 border w-3/6 h-7 px-3" autocomplete="off"><br>
                                        <span class="feedback" v-if="errors.password_form.current_password[0]">{{ errors.password_form.current_password[0] }}</span>
                                    </div>
                                    <div :class="{ 'has-error': errors.password_form.new_password[0] }">
                                        <input v-model="form.password_form.new_password" @change="reset('password_form', 'new_password')" type="password" :placeholder="$t('settingsView.newPassword')" class="mt-3 border w-3/6 h-7 px-3" autocomplete="off"><br>
                                        <span class="feedback" v-if="errors.password_form.new_password[0]">{{ errors.password_form.new_password[0] }}</span>
                                    </div>
                                    <div :class="{ 'has-error': errors.password_form.repeat_password[0] }">
                                        <input v-model="form.password_form.repeat_password" @change="reset('password_form', 'repeat_password')" type="password" :placeholder="$t('settingsView.repeatPassword')" class="mt-3  w-3/6 border h-7 px-3" autocomplete="off">
                                        <span class="feedback" v-if="errors.password_form.repeat_password[0]">{{ errors.password_form.repeat_password[0] }}</span>
                                    </div>

                                    <button type="submit" class="button-primary button-sm mt-6 flex items-center justify-center w-1/2 px-8 py-2 radius-sm">
                                      {{ $t('settingsView.changePassword') }}</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab == 't2'" class="px-6 pt-8 flex flex-col h-full max-h-full overflow-y-auto">
                   <!-- {{ viewSettings }}<br>
                    {{ form }}<br><br>
                    {{  authUser }} -->
                    <div class="w-1/3">
                        <div class="section mb-8">
                            <h3 class="heading-6-medium border-b">{{ $t('settingsView.linesPerPage') }}</h3>
                            <form v-on:submit.prevent="changeViewSettings">
                                <div :class="{ 'has-error': errors.view_settings_form.per_page[0] }">
                                    <select v-model="form.view_settings_form.per_page"  :key="form.view_settings_form.per_page"
                                        @change="reset('view_settings_form', 'per_page')"
                                        :placeholder="$t('settingsView.linesPerPage')"
                                        class="mt-3 border w-3/6 h-7 px-3" autocomplete="off">
                                        <option value="4">4</option>
                                        <option value="8">8</option>
                                        <option value="16">16</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span class="feedback" v-if="errors.view_settings_form.per_page[0]">{{ errors.view_settings_form.per_page[0] }}</span>
                                </div>
                                <button ref="settingsSaveBtn" type="submit" class="button-primary button-sm mt-6 flex items-center justify-center w-1/2 px-8 py-2 radius-sm">
                                  {{ $t('settingsView.save') }}</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted,  } from 'vue'
import { useRoute } from "vue-router";

const store = useStore()
const route = useRoute()

const lang = route?.query?.lang || 'de';

let authUser = computed(() => store.getters['auth/user'])

const settingsSaveBtn = ref(null)

let activeTab = ref('t1') //computed(() => store.getters['app/activeTab'])

let setActiveTab = function (tab) {
    activeTab.value = tab
}

const form = reactive({
    password_form: {
        current_password: '',
        new_password: '',
        repeat_password: '',
    },
    view_settings_form: {
        per_page: '',
    }
})

let errors = reactive({
    password_form: {
        current_password: [],
        new_password: [],
        repeat_password: [],
    },
    view_settings_form: {
        per_page: []
    }
})

const reset = (formName, field) => {
    console.log(formName, field)
    errors[formName][field] = []
}

let changePassword = async() => {
    let payload = toRaw(form.password_form)

    console.log(JSON.stringify(payload))

    let resp = await store.dispatch('auth/changePassword', payload).catch((err) => {
        Object.assign(errors.password_form, err.errors)
    })

    console.log('resp', resp)

    if (resp) {
        alert('OK, das Passwort wurde geändert!')

        form.password_form['current_password'] = ''
        form.password_form['new_password'] = ''
        form.password_form['repeat_password'] = ''
    }
}

let changeViewSettings = async() => {
    let payload = toRaw(form.view_settings_form)

    console.log(JSON.stringify(payload))

    let resp = await store.dispatch('auth/saveViewSettings', payload).catch((err) => {})
    console.log('resp', resp)

    if (resp) {
        store.dispatch('auth/user', lang)
        alert('Die Einstellungen wurden gespeichert!')
    }

    settingsSaveBtn.value.blur()
}

onMounted(async() => {
    store.dispatch('app/showMenu', true)

    await store.dispatch('auth/user', lang)

    form.view_settings_form.per_page = authUser.value.view_settings["patients.per_page"]
})

</script>
<style scoped>
.tabs {
    margin-bottom: -3px;
}
.tab-container {
    background: var(--color-shade-3);
    /*border-bottom: 3px solid var(--color-border);*/
    border-bottom: 3px solid transparent;
}
.tabs a {
  opacity: 1;
  text-align: center;
  font-size: 14px;
  line-height: 1.33;
  font-weight: 500;
  color: var(--color-neutral-700);
  border-bottom: 3px solid transparent;
}

.tabs a.active {
    border-bottom: 3px solid var(--color-primary-500);
    color: var(--color-primary-500);
}
</style>